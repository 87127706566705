import React from "react"
import Layout from "src/component/layout/layout"
import { StaticImage } from "gatsby-plugin-image"
import AiCasestudyListLatest3posts from "src/component/list/aiCasestudyListLatest3posts"
import { Seo } from "src/component/common/seo"
import { graphql } from "gatsby"
import AiPlatformLogo from '../../assets/img/pages/top/logo_ai_platform.svg';

export default function AiPlatform({ data }) {
    const filteredItems = data.allMicrocmsAiServices.edges.filter(({ node }) =>
     node.category.some(cat => cat.id === "ai-platform")
    )
    
        React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout>
              <div className="ai-series-wrap">
                <div className="ai-series">
                    <div className="ai-series__inner c-inner-primary">
                            <h1 className="ai-series__title">
                                <img src={AiPlatformLogo} alt="AI Platform" className="title-logo" />
                            </h1>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-pc js-scroll">
                                <div><div><span>社内外の非構造化データを資産に変える、AIデータ基盤</span></div></div>
                            </div>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-sp js-scroll">
                                <div><div><span>社内外の非構造化データを資産に変える、</span></div></div>
                                <div><div><span>AIデータ基盤</span></div></div>
                        </div>
         
                        <div className="ai-series__policy">
                            <p className="ai-series__lead">
                                    非構造化データを自動で構造化し、専門知識がなくても高度なデータ分析を可能にします。
                                </p>
                                <p className="ai-series__lead">
                                    紙の報告書、IoT、音声、画像データなどの非構造化データを自動で分析可能な形式に変換。
                                    <br />
                                    BIをはじめとする多彩な分析ツールとの連携も可能で、
                                    <br />
                                    生成AIやAutoMLなどの最新技術をノーコードで手軽に活用できます。
                                    <br />
                                    クラウド型で迅速な構築、低コスト導入ができ、目指すデータ活用を最短距離で実現します。
                            </p>
                       </div>
                    </div>
                </div>

               

                <section className="ai-series-features">
                    <div className="ai-series-features__inner c-inner-primary">
                        <ul>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 01</div>
                                <h3>高度な分析に必須の「非構造化データ」を自動で構造化</h3>
                                <p>従来のシステムでは実装に時間の掛かる文書、音声、画像、IoTデータなどの非構造化データを自動的に構造化します。
                                    <br />
                                    <br />
                                    たとえば、紙の報告書をAI-OCRでデジタル化し、データベース化することで、社内のナレッジを容易に検索・分析可能に。また、IoTセンサーから取得した膨大なデータをリアルタイムで処理し、生産現場や施設管理に活用することができます。非構造化データの活用により、新しいビジネス価値を創出する基盤を提供します。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 02</div>
                                <h3>多彩な分析ツールと連携し、データ活用の可能性を拡張</h3>
                                <p>DATAFLUCTが提供する分析ツールはもちろん、ETLサービスやBI、生成AIなどの最先端ツールとシームレスに連携します。
                                    <br />
                                    <br />
                                   これにより、社内の各部署が導入するSaaSサービスから分析に必要なデータを連携することや、データ分析の結果を生成AIによる対話形式で伝達することも可能です。また、SNS投稿や天候などの外部データを分析に活用するなど複雑な分析業務も可能で、企業全体のデータ活用の幅を大きく広げます。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 03</div>
                                <h3>迅速・低コストに導入できる次世代データ基盤</h3>
                                <p>初期構築を数週間で完了させることが可能です。学習コストを大幅に削減する設計で、社内の誰もが最新技術にアクセスできる環境を提供し、全社的なDXを推進します。
                                    <br />
                                    <br />
                                   AIエージェントによる業務の自律化を実現するための基盤として必要な高度な機能を揃えており、単なるデータ管理を超えた、データを活用した高度なビジネス価値創出を支援します。
                                </p>
                            </li>
                        </ul>
                     
                    </div>
                    </section>
                    
                    {/* 機能追加するcode DON'T delete */}
                {/* <section className="ai-functions">
                    <div className="ai-functions__inner c-inner-primary">
                        <div className="ai-functions__title c-title01">
                            <h2 className="ai-functions__title__text c-title01__text">機能紹介</h2>
                        </div>
                    <ul className="ai-functions__list">
                        {filteredItems.map(({ node }) => (
                            <li key={node.id}>
                                <div className="ai-functions__list__image">
                                    <div className="ai-functions__list__image__inner">
                                        <img src={node.thumnail.url} alt={node.title} width={node.thumnail.width} height={node.thumnail.height} />
                                    </div>
                                </div>
                                <div 
                                    className={`ai-functions__list__text ${node.button && node.link ? 'has-button' : 'no-button'}`} 
                                >
                                    <h3 className="ai-functions__list__title">
                                        <span className="is-title">{node.title}</span>
                                    </h3>
                                    <p className="ai-functions__list__lead">{node.content}</p>
                                </div>
                                {node.button && node.link && (
                                    <div className="ai-functions__list__button">
                                        <div className="ai-functions__list__button__inner">
                                            <Button external path={node.link} className="c-button01" label="詳しく見る" />
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                     </ul>
                    </div>
                </section> */}
                

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <AiCasestudyListLatest3posts />
                    </div>
                </section>
             </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="AI PLATFORM"
            description=""
            pathname=""
        />
    )
}

export const query = graphql`
    query {
        allMicrocmsAiServices {
            edges {
                node {
                    id
                    createdAt
                    updatedAt
                    title
                    content
                    thumnail {
                        url
                        width
                        height
                    }
                    category {
                        id
                        name
                    }
                    button
                    link
                }
            }
        }
    }
`
